<template>
    <v-dialog v-model="dialogCambioBodega" persistent max-width="550px">
        <v-card>
            <v-toolbar class="text-h6" color="primary" dark>
                <span>Bodegas relacionadas al usuario: {{this.auth.username}}</span>
            </v-toolbar>

            <v-card-text class="mt-4 pb-0">
                <v-autocomplete
                    v-model="bodegaDestino"
                    :items="listaBodegasPorUsuario"
                    :item-text="mostrarBodegasPorUsuario"
                    return-object
                    dense
                    label="Seleccione una bodega"
                    outlined
                ></v-autocomplete>
            </v-card-text>
  
            <v-card-actions>
            <v-spacer></v-spacer>
            <!-- Botón para cancelar -->
            <v-btn color="red" text @click="cerrarDialogCambioBodega()">Cerrar</v-btn>
            <!-- Botón para aceptar la selección -->
            <v-btn color="green" text @click="cambiarBodega()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </template>
  
  <script>
  import { mapMutations, mapState } from "vuex";
  export default {
    props: {
        dialogCambioBodega: Boolean,
    },
    created(){
        this.listarBodegasPorUsuario();
    },

    computed: {
    ...mapState(["enterprise", "auth"]),
    },

    data() {
      return {
        listaBodegasPorUsuario: {},
        bodegaDestino: {} 
      };
    },

    methods: {

        ...mapMutations([
        "showSuccess",
        "showError"
        ]),

        listarBodegasPorUsuario(){
            this.$http.get("/msa-administration/usuarioBodega/listaBodegaPorUsuario", {
            params:{
                usuario: this.auth.username
            }
            }).then((result) => {
                this.listaBodegasPorUsuario = result.data;
            })
        },

        cambiarBodega(){
            if((this.auth.username == '' || this.bodegaDestino?.codigoBodega == null) ){
                this.showError("El usuario o la bodega no existe")
                return;
            }
            this.$http.post("/msa-administration/usuarioBodega/cambiarBodega", {
                usuario: this.auth.username,
                bodegaDestino: this.bodegaDestino?.codigoBodega
            }).then(async (result) => {
                await this.cerrarDialogCambioBodega()
                if(result.data.response === 1){
                    this.showSuccess(result.data.mensaje)
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                }
                if(result.data.response === 2){
                    this.showSuccess(result.data.mensaje)
                }
                if(result.data.response != 1 && result.data.response != 2){
                    this.showError(result.data.mensaje)
                }
            }).catch(async () => {
                this.showError("Error inesperado por favor contacte con un administrador")
                await this.cerrarDialogCambioBodega()
            })
        },

        cerrarDialogCambioBodega(){
        return new Promise((resolve) => {
                this.$emit('cerrarDialogCambioBodega');
                this.bodegaDestino = {};
                resolve(true)
            })
        },

        mostrarBodegasPorUsuario(item){
            return item?.nombreBodega + " - " + item?.codigoBodega
        },

        acceptSelection() {
            if (this.selectedName) {
                this.$emit("select-name", this.selectedName);
            }
        },

        closeDialog() {
            this.dialog = false;
            this.selectedName = null; 
        }
    },

    watch:{
        "dialogCambioBodega": function () {
            if(this.dialogCambioBodega == true){
                this.listarBodegasPorUsuario();
            }    
        },
    }
  };
  </script>
  
  <style scoped>
  /* Agrega estilos personalizados aquí si es necesario */
  </style>
  