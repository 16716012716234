<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="d-flex align-center pa-2 header">
    <v-btn icon color="white" :to="{ name: 'modules.enterprise' }">
      <v-list-item-avatar class="quitarRadio ms-5">
        <v-img src="../assets/herinco-small.png" />
      </v-list-item-avatar>
    </v-btn>
    <img class="ms-4" src="../assets/images/logoCohan.png" />
    <v-spacer></v-spacer>
    <span class="textAnimate" color="blue darken-4">{{ this.enterprise.name }}</span>
    <v-spacer></v-spacer>
    <v-btn icon color="info" :to="{ name: 'modules.help' }">
      <v-icon>help</v-icon>
    </v-btn>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon color="amber darken-2" class="mx-4" v-bind="attrs" v-on="on">
          <v-icon>notifications</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <span class="text-h6">Notificaciones</span>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item link>
          <v-icon class="me-3">notifications</v-icon> Notificación
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-avatar class="blueGlobal" size="40" v-bind="attrs" v-on="on">
          <span class="text-capitalize white--text">{{ user[0] }}</span>
        </v-avatar>
      </template>

      <v-card max-width="400px">
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar class="blueGlobal" size="40">
                <span class="text-capitalize white--text">{{ user[0] }}</span>
              </v-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="text-capitalize">{{ user }}</v-list-item-title>
              <v-list-item-subtitle class="text-capitalize">{{ this.enterprise.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>

          <v-list-item link>

            <v-dialog transition="dialog-top-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title v-bind="attrs" v-on="on">
                  <v-icon class="me-2">settings</v-icon>
                  <span>Configuración</span>
                </v-list-item-title>
              </template>
              <!-- modal de configuraciones de usuario -->
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark>Opening from the top</v-toolbar>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-avatar class="blueGlobal" size="42">
                          <span class="text-capitalize white--text">{{ user[0] }}</span>
                        </v-avatar>
                        <span class="text-capitalize mx-2 text-h5">{{ user }}</span>
                      </v-col>
                      <v-divider></v-divider>
                      <v-col cols="12">
                        <span class="me-3">Permisos</span>
                        <v-chip> Permiso </v-chip>
                        <v-chip> Permiso </v-chip>
                        <v-chip> Permiso </v-chip>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Email*" required></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="Contraseña*" type="password" required></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-card-actions class="justify-end">
                    <v-btn text @click="dialog.value = false">Cerrar</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-list-item>
          <CambioBodega :dialogCambioBodega="dialogCambioBodega" @cerrarDialogCambioBodega="cerrarDialogCambioBodega"/>
          <v-list-item link v-if="this.enterprise.code != ''">
            <v-list-item-title @click="dialogCambioBodega = true">
              <v-icon class="me-2">inventory_2</v-icon>
              <span>Cambiar bodega</span>
            </v-list-item-title>
          </v-list-item>

          <v-list-item link>
            <v-list-item-title @click="() => logout()">
              <v-icon class="me-2">logout</v-icon>
              <span>Salir</span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>


<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->

<script>
import { mapState } from "vuex";
import CambioBodega from '@/components/CambioBodega.vue';

export default {
  components:{
    CambioBodega,
  },
  name: "Header",
  props: {
    user: {
      type: String,
      default: "Aser"
    },
    rol: {
      type: Array,
      default: () => ["rol"]
    },
    logout: {
      type: Function,
      default: () => { }
    }
  },
  data: () => ({
    menu: false,
    dialogCambioBodega: false
  }),

  computed: {
    ...mapState(["enterprise"]),
  },
  methods:{
    cerrarDialogCambioBodega(){
      this.dialogCambioBodega = false;
    }
  }
}
</script>


<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->

<style scoped>
@import url(https://fonts.googleapis.com/css?family=Open+Sans:700);

.header {
  border-bottom: solid 1px rgb(218, 217, 217);
  background-color: white;
  position: sticky;
  top: 0;
  height: 53px !important;
  z-index: 100
}

/* 
.iconHome {
  z-index: 100
} */

.quitarRadio {
  border-radius: 0%;
}

.textAnimate {
  color: #0D47A1;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  -webkit-animation: tracking-in-contract-bck 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  animation: tracking-in-contract-bck 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.blueGlobal {
  background: #0D47A1 !important;
  color: white !important;
}

@-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
</style>