/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Jaime Javier Coronado Guerrero                                     ###### 
###### @date: Octubre 2024                                                     ###### 
##################################################################################*/

const intrahospitalario = () => import('../Intrahospitalario.vue')
const reporteDespachos = () => import('../ReporteDespachos.vue')

import { Role } from "@/router/role.js";

const intrahospitalarioRoutes = [
  {
    path: 'intrahospitalario',
    name: 'modules.reports.intrahospitalario',
    component: intrahospitalario,
    children: [
      {
        path: 'reporte-despachos',
        name: 'modules.reports.intrahospitalario.reporteDespachos',
        component: reporteDespachos,
        meta: { authorize: Role.intrahospitalarioRoutes && Role.Reportes_Intrahospitalario_ReporteDespachos }
      },
    ],
    meta: { authorize: Role.intrahospitalarioRoutes }
  },

];

export default intrahospitalarioRoutes;