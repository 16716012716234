/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

const Administrative = () => import('../Administrative.vue');
const Aseguradora = () => import('../Aseguradora.vue');
const Contrato = () => import('../Contrato.vue');
const RelateProduct = () => import('../RelateProduct.vue');
const PagadorContrato = () => import('../PagadorContrato.vue');
const CrearUsuarios = () => import('../CrearUsuarios.vue');
const Bodegas = () => import('../Bodegas.vue');
const Auditoria = () => import('../Auditoria.vue');
const ContratoProducto = () => import('../ContratoProducto.vue');
const Pagador = () => import('../Pagador.vue');
const PagadorAseguradora = () => import('../PagadorAseguradora.vue');
const Productos = () => import('../Productos.vue');
const Taquillas = () => import('../Taquillas.vue');
const UsuarioAuditoria = () => import('../UsuarioAuditoria.vue');
const UsuariosAseguradoras = () => import('../UsuariosAseguradoras.vue');
const UsuariosBodegas = () => import('../UsuariosBodegas.vue');
const Medicos = () => import('../Medicos.vue');
const Prestador = () => import('../Prestador.vue');
const AseguradoraPrestador = () => import('../AseguradoraPrestador.vue');
const Norma = () => import('../Norma.vue');
const UsuarioEmpresa = () => import('../UsuarioEmpresa.vue');
const Copago = () => import('../Copago.vue');
const CuotaModeradora = () => import('../CuotaModeradora.vue');
import { Role } from "@/router/role.js";


const administrativeRoutes = [
  {
    path: 'administrative',
    name: 'modules.settings.administrative',
    component: Administrative,
    children: [
      {
        path: 'aseguradora',
        name: 'modules.settings.administrative.aseguradora',
        component: Aseguradora,
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_Aseguradora }
      },
      {
        path: 'contrato',
        name: 'modules.settings.administrative.contrato',
        component: Contrato,
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_Contrato }
      },
      {
        path: 'producto-bodega',
        name: 'modules.settings.administrative.productoBodega',
        component: RelateProduct,
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_ProductoBodega }
      },
      {
        path: 'pagador-contrato',
        name: 'modules.settings.administrative.pagadorContrato',
        component: PagadorContrato,
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_PagadorContrato }
      },
      {
        path: 'usuarios',
        name: 'modules.settings.administrative.usuarios',
        component: CrearUsuarios,
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_Usuarios }
      },
      {
        path: 'bodegas',
        name: 'modules.settings.administrative.bodegas',
        component: Bodegas,
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_Bodegas }
      },
      {
        path: 'auditoria',
        name: 'modules.settings.administrative.auditoria',
        component: Auditoria,
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_Auditoria }
      },
      {
        path: 'contrato-producto',
        name: 'modules.settings.administrative.contratoProducto',
        component: ContratoProducto,
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_ContratoProducto }
      },
      {
        path: 'pagador',
        name: 'modules.settings.administrative.pagador',
        component: Pagador,
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_Pagador }
      },
      {
        path: 'pagador-aseguradora',
        name: 'modules.settings.administrative.pagadorAseguradora',
        component: PagadorAseguradora,
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_PagadorAseguradora }
      },
      {
        path: 'productos',
        name: 'modules.settings.administrative.productos',
        component: Productos, 
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_Productos }
      },
      {
        path: 'taquillas',
        name: 'modules.settings.administrative.taquillas',
        component: Taquillas,
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_Taquillas }
      },
      {
        path: 'usuario-auditoria',
        name: 'modules.settings.administrative.usuarioAuditoria',
        component: UsuarioAuditoria,
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_UsuarioAuditoria }
      },
      {
        path: 'usuario-aseguradora',
        name: 'modules.settings.administrative.usuarioAseguradora',
        component: UsuariosAseguradoras,
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_UsuarioAseguradora }
      },
      {
        path: 'usuarios-bodegas',
        name: 'modules.settings.administrative.usuariosBodegas',
        component: UsuariosBodegas,
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_UsuariosBodegas }
      },
      {
        path: 'medicos',
        name: 'modules.settings.administrative.medicos',
        component: Medicos,
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_Medicos }
      },
      {
        path: 'prestador',
        name: 'modules.settings.administrative.prestador',
        component: Prestador,
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_Prestador }
      },
      {
        path: 'aseguradoraPrestador',
        name: 'modules.settings.administrative.aseguradoraPrestador',
        component: AseguradoraPrestador,
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_AseguradoraPrestador }
      },
      {
        path: 'norma',
        name: 'modules.settings.administrative.norma',
        component: Norma,
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_Norma }
      },
      {
        path: 'usuario-empresa',
        name: 'modules.settings.administrative.usuario-empresa',
        component: UsuarioEmpresa,
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_UsuarioEmpresa }
      },
      {
        path: 'copago',
        name: 'modules.settings.administrative.copago',
        component: Copago,
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_Copago }
      },
      {
        path: 'cuotaModeradora',
        name: 'modules.settings.administrative.cuotaModeradora',
        component: CuotaModeradora,
        meta: { authorize: Role.Configuraciones_Administrativo && Role.Configuraciones_Administrativo_CuotaModeradora }
      },
    ],
    meta: { authorize: Role.Configuraciones_Administrativo }
  },
];


export default administrativeRoutes;