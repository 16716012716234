export const Role = {
    Configuraciones: 'Configuraciones',
    Configuraciones_ServiciosFarmaceuticos: 'Configuraciones_ServiciosFarmaceuticos',
    Configuraciones_ServiciosFarmaceuticos_Roles: 'Configuraciones_ServiciosFarmaceuticos_Roles',
    Configuraciones_ServiciosFarmaceuticos_Afiliados: 'Configuraciones_ServiciosFarmaceuticos_Afiliados',
    Configuraciones_ServiciosFarmaceuticos_Afiliados_eliminar: 'Configuraciones_ServiciosFarmaceuticos_Afiliados_eliminar',
    Configuraciones_ServiciosFarmaceuticos_Medios: 'Configuraciones_ServiciosFarmaceuticos_Medios',
    Configuraciones_ServiciosFarmaceuticos_Homologacion: 'Configuraciones_ServiciosFarmaceuticos_Homologacion',
    Configuraciones_ServiciosFarmaceuticos_MotivosIntrahospitalario: 'Configuraciones_ServiciosFarmaceuticos_MotivosIntrahospitalario',
    Configuraciones_ServiciosFarmaceuticos_AfiliadoRuta: 'Configuraciones_ServiciosFarmaceuticos_AfiliadoRuta',
    Configuraciones_ServiciosFarmaceuticos_Restricciones: 'Configuraciones_ServiciosFarmaceuticos_Restricciones',
    Configuraciones_ServiciosFarmaceuticos_ParametrizacionCorreos: 'Configuraciones_ServiciosFarmaceuticos_ParametrizacionCorreos',
    Configuraciones_ServiciosFarmaceuticos_Notificaciones: 'Configuraciones_ServiciosFarmaceuticos_Notificaciones',
    Configuraciones_Sistema: 'Configuraciones_Sistema',
    Configuraciones_Sistema_ContratosPorUsuario: 'Configuraciones_Sistema_ContratosPorUsuario',
    Configuraciones_Sistema_Clientes: 'Configuraciones_Sistema_Clientes',
    Configuraciones_Sistema_Tasks: 'Configuraciones_Sistema_Tasks',
    Configuraciones_Sistema_ParametrizacionTablero: 'Configuraciones_Sistema_ParametrizacionTablero',
    Configuraciones_Administrativo: 'Configuraciones_Administrativo',
    Configuraciones_Administrativo_Aseguradora: 'Configuraciones_Administrativo_Aseguradora',
    Configuraciones_Administrativo_Contrato: 'Configuraciones_Administrativo_Contrato',
    Configuraciones_Administrativo_Bodegas: 'Configuraciones_Administrativo_Bodegas',
    Configuraciones_Administrativo_RelacionarProducto: 'Configuraciones_Administrativo_ProductoBodega',
    Configuraciones_Administrativo_PagadorContrato: 'Configuraciones_Administrativo_PagadorContrato',
    Configuraciones_Administrativo_UsuarioAseguradora: 'Configuraciones_Administrativo_UsuarioAseguradora',
    Configuraciones_Administrativo_UsuariosBodegas: 'Configuraciones_Administrativo_UsuariosBodegas',
    Configuraciones_Administrativo_Usuarios: 'Configuraciones_Administrativo_Usuarios',
    Configuraciones_Administrativo_Usuarios_Crear: 'Configuraciones_Administrativo_Usuarios_Crear',
    Configuraciones_Administrativo_Usuarios_Editar: 'Configuraciones_Administrativo_Usuarios_Editar',
    Configuraciones_Administrativo_Usuarios_Editar_Usuario: 'Configuraciones_Administrativo_Usuarios_Editar_Usuario',
    Configuraciones_Administrativo_Usuarios_Editar_Bodega: 'Configuraciones_Administrativo_Usuarios_Editar_Bodega',
    Configuraciones_Administrativo_Usuarios_Editar_Estado: 'Configuraciones_Administrativo_Usuarios_Editar_Estado',
    Configuraciones_Administrativo_Usuarios_Editar_Password: 'Configuraciones_Administrativo_Usuarios_Editar_Password',
    Configuraciones_Administrativo_Productos: 'Configuraciones_Administrativo_Productos',
    Configuraciones_Administrativo_ContratoProducto: 'Configuraciones_Administrativo_ContratoProducto',
    Configuraciones_Administrativo_Taquillas: 'Configuraciones_Administrativo_Taquillas',
    Configuraciones_Administrativo_Taquillas_Administrar: 'Configuraciones_Administrativo_Taquillas_Administrar',
    Configuraciones_Administrativo_Taquillas_Cambiar: 'Configuraciones_Administrativo_Taquillas_Cambiar',
    Configuraciones_Administrativo_Pagador: 'Configuraciones_Administrativo_Pagador',
    Configuraciones_Administrativo_PagadorAseguradora: 'Configuraciones_Administrativo_PagadorAseguradora',
    Configuraciones_Administrativo_Auditoria: 'Configuraciones_Administrativo_Auditoria',
    Configuraciones_Administrativo_UsuarioAuditoria: 'Configuraciones_Administrativo_UsuarioAuditoria',
    Configuraciones_Administrativo_Medicos: 'Configuraciones_Administrativo_Medicos',
    Configuraciones_Administrativo_Prestador: 'Configuraciones_Administrativo_Prestador',
    Configuraciones_Administrativo_AseguradoraPrestador: 'Configuraciones_Administrativo_AseguradoraPrestador',
    Configuraciones_Administrativo_Norma: 'Configuraciones_Administrativo_Norma',
    Configuraciones_Administrativo_Norma_Maestro: 'Configuraciones_Administrativo_Norma_Maestro',
    Configuraciones_Administrativo_UsuarioEmpresa: 'Configuraciones_Administrativo_UsuarioEmpresa',
    Configuraciones_Administrativo_Copago: 'Configuraciones_Administrativo_Copago',
    Configuraciones_Administrativo_CuotaModeradora: 'Configuraciones_Administrativo_CuotaModeradora',

    ServiciosFarmaceuticos: 'ServiciosFarmaceuticos',
    ServiciosFarmaceuticos_Dispensacion: 'ServiciosFarmaceuticos_Dispensacion',
    ServiciosFarmaceuticos_Dispensacion_Ambulatorio: 'ServiciosFarmaceuticos_Dispensacion_Ambulatorio',
    ServiciosFarmaceuticos_Dispensacion_Intrahospitalario: 'ServiciosFarmaceuticos_Dispensacion_Intrahospitalario',
    ServiciosFarmaceuticos_MallaAutoinmunes: 'ServiciosFarmaceuticos_MallaAutoinmunes',
    ServiciosFarmaceuticos_MallaAutoinmunes_CargarMalla: 'ServiciosFarmaceuticos_MallaAutoinmunes_CargarMalla',
    ServiciosFarmaceuticos_Dispensacion_Solicitud: 'ServiciosFarmaceuticos_Dispensacion_Solicitud',

    Herramientas: 'Herramientas',
    Herramientas_Homologador: 'Herramientas_Homologador',
    Herramientas_Homologador_Homologacion: 'Herramientas_Homologador_Homologacion',
    Herramientas_Homologador_HistoricoHomologacion: 'Herramientas_Homologador_HistoricoHomologacion',
    Herramientas_Homologador_clientes: 'H-01',

    Analitica: 'Analitica',
    Analitica_Planeacion: 'Analitica_Planeacion',
    Analitica_Comercial: 'Analitica_Comercial',
    Analitica_Compras: 'Analitica_Compras',
    Analitica_Logistica: 'Analitica_Logistica',
    Analitica_ServiciosFarmaceuticos: 'Analitica_ServiciosFarmaceuticos',
    Analitica_Facturacion: 'Analitica_Facturacion',
    Analitica_TalentoHumano: 'Analitica_TalentoHumano',
    Analitica_ServiciosSalud: 'Analitica_ServiciosSalud',
    Analitica_Administrativo: 'Analitica_Administrativo',
    Analitica_Tableros: 'Analitica_Tableros',
    Analitica_Tableros_InformesBI: 'Analitica_Tableros_InformesBI',
    
    Logistica: 'Logistica',
    Logistica_Tracking: 'Logistica_Tracking',
    Logistica_Tracking_Rastreo: 'Logistica_Tracking_Rastreo',

    add_portafolio_homologar: 'add_portafolio_homologar',
    
    Dispensacion_Usuario_Picking: 'Dispensacion_Usuario_Picking',
    Dispensacion_Usuario_Caja: 'Dispensacion_Usuario_Caja',
    Dispensacion_Usuario_Administrativo: 'Dispensacion_Usuario_Administrativo',
    Dispensacion_Usuario_Regente: 'Dispensacion_Usuario_Regente',
    Dispensacion_Usuario_Admin: 'Dispensacion_Usuario_Admin',
    Dispensacion_Usuario_Roles_bodegas: 'Dispensacion_Usuario_Roles_bodegas',

    Reportes: 'Reportes',
    Reportes_Logistica: 'Reportes_Logistica',
    Reportes_Logistica_Tracking: 'Reportes_Logistica_Tracking',
    Reportes_Logistica_TrackingEntregas: 'Reportes_Logistica_TrackingEntregas',
    Reportes_Dispensacion: 'Reportes_Dispensacion',
    Reportes_Dispensacion_SolicitudMedicamentos: 'Reportes_Dispensacion_SolicitudMedicamentos',
    Reportes_RutaAutoinmunes: 'Reportes_RutaAutoinmunes',
    Reportes_RutaAutoinmunes_Solicitudes: 'Reportes_RutaAutoinmunes_Solicitudes',
    Reportes_Intrahospitalario: 'Reportes_Intrahospitalario',
    Reportes_Intrahospitalario_ReporteDespachos: 'Reportes_Intrahospitalario_ReporteDespachos',

    MallaValidadora: 'MallaValidadora',
    MallaValidadora_SolicitudServicio: 'MallaValidadora_SolicitudServicio',
    MallaValidadora_SolicitudServicio_FormularioSolicitud: 'MallaValidadora_SolicitudServicio_FormularioSolicitud',

    MallaValidadora_SolicitudServicio_FormularioSolicitud_Interno:'MallaValidadora_SolicitudServicio_FormularioSolicitud_Interno',
    MallaValidadora_SolicitudServicio_FormularioSolicitud_Externo_solicitante:'MallaValidadora_SolicitudServicio_FormularioSolicitud_ExternoSolicitante',
    MallaValidadora_SolicitudServicio_FormularioSolicitud_Externo_prestador:'MallaValidadora_SolicitudServicio_FormularioSolicitud_ExternoPrestador'

}