/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Diego Alexander Villalba                                      ###### 
###### @date: Octubre 2023                                                    ###### 
##################################################################################*/

import { Role } from "@/router/role.js";
import logisticsRoutes from './logistics/routes/logistics.routes.js';
import dispensationRoutes from './dispensation/routes/dispensation.routes.js';
import autoimmunePathwayRoutes from './autoimmune-pathway/routes/autoimmunePathway.routes.js';
import intrahospitalarioRoutes from "./intrahospitalario/routes/intrahospitalario.routes.js";
const Reports = () => import('./Reports.vue');

const reportsRoutes = [
  {
    path: '/modules/reports',
    name: 'modules.reports',
    component: Reports,
    children: [
      ...logisticsRoutes,
      ...dispensationRoutes,
      ...intrahospitalarioRoutes,
      ...autoimmunePathwayRoutes,
    ],
    meta: { authorize: Role.Reportes }
  },
];


export default reportsRoutes;