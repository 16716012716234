/*################################################################################## 
###### HERINCOHAN                                                             ###### 
###### @author: Estefania Villada Chavarria                                   ###### 
###### @date: Septiembre 2024                                                 ###### 
##################################################################################*/

const AutoimmunePathway = () => import('../AutoimmunePathway.vue');
const Solicitudes = () => import('../Solicitudes.vue');

import { Role } from "@/router/role.js";


const autoimmunePathwayRoutes = [
  {
    path: 'autoimmune-pathway',
    name: 'modules.reports.autoimmunePathway',
    component: AutoimmunePathway,
    children: [
      {
        path: 'solicitudes',
        name: 'modules.reports.autoimmunePathway.solicitudes',
        component: Solicitudes,
        meta: { authorize: Role.autoimmunePathwayRoutes && Role.Reportes_RutaAutoinmunes_Solicitudes }
      },
    ],
    meta: { authorize: Role.autoimmunePathwayRoutes }
  },

];

export default autoimmunePathwayRoutes;