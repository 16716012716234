<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Febrero 2024                                                  ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <v-app>
        <v-main style="padding: 0 0 !important; padding-bottom: 2rem; overflow-y: auto;" class="fill-height d-flex align-center justify-center">
            <div class="d-flex justify-center">
                <img class="shadow-img" style="width: 200px;" src="@/assets/images/logoCohanMasVital.png"/>
            </div>
            <div class="d-flex justify-center">
                <!-- Modal para agregar datosAfiliado -->
                <v-card class="elevation-3 mr-5 ml-5" style="width: 85% !important;">
                    <v-card-title class="headline justify-center pb-0 pt-3">{{ tituloFormulario }}</v-card-title>
                    <v-card-text>
                        <v-container class="mt-2">
                            <!-- formulario para agredar datosAfiliado -->
                            <validation-observer v-slot="{ invalid }" ref="observer">
                                <v-form ref="form" lazy-validation>
                                    <!--campos: tipo y número de identificación, sexo y fecha de nacimiento-->
                                    <v-row class="pr-3" align="start">
                                        <v-col cols="12" sm="6" md="3" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: true, }" ref="tiposDeDocumento">
                                                <v-select ref="select" id="tipoDocumento" v-model="datosAfiliado.tipoDocumento" :items="tiposDoc" outlined  
                                                    :menu-props="menuProps" :error-messages="errors" disabled
                                                    dense label="Tipo de identificación" @click="listarTiposDeDocumentos()">
                                                </v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: true }">
                                                <v-text-field v-model="datosAfiliado.numeroDocumento" :error-messages="errors"
                                                    label="N° de documento" dense outlined maxlength="15" disabled>
                                                </v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: true }" style="max-width: 100%;">
                                                <v-select v-model="datosAfiliado.generoValue" :items="generoAfiliados" label="Sexo" :error-messages="errors"
                                                    dense outlined :menu-props="menuProps">
                                                </v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3" class="pr-0 pt-0 pb-0">
                                            <v-dialog ref="menu" v-model="menu2" width="290px" transition="scale-transition">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <validation-provider v-slot="{ errors }" :rules="{ required: true }">
                                                        <v-text-field v-model="datosAfiliado.fechaNacimiento" label="Fecha de nacimiento" 
                                                            :error-messages="errors" readonly v-bind="attrs" v-on="on" dense outlined>
                                                        </v-text-field>
                                                    </validation-provider>
                                                </template>
                                                <v-date-picker v-model="datosAfiliado.fechaNacimiento" scrollable 
                                                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                                    min="1950-01-01" locale="es-co" :first-day-of-week="1" @change="save">
                                                </v-date-picker>
                                            </v-dialog>
                                        </v-col>
                                    </v-row>
                                    <!--campos: nombres y apellidos-->
                                    <v-row class="pr-3" align="start">
                                        <v-col cols="12" sm="6" md="3" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: true, }">
                                                <v-text-field v-model="datosAfiliado.primerNombre" :error-messages="errors"
                                                    label="Primer nombre" dense outlined>
                                                </v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3" class="pr-0 pt-0 pb-0">
                                            <validation-provider>
                                                <v-text-field v-model="datosAfiliado.segundoNombre" label="Segundo nombre" dense
                                                    outlined>
                                                </v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: true }">
                                                <v-text-field v-model="datosAfiliado.primerApellido" :error-messages="errors"
                                                    label="Primer apellido" dense outlined>
                                                </v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="3" class="pr-0 pt-0 pb-0">
                                            <validation-provider>
                                                <v-text-field v-model="datosAfiliado.segundoApellido" label="Segundo apellido" dense outlined>
                                                </v-text-field>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    <!--campos: departamento, ciudad y dirección-->
                                    <v-row class="pr-3" align="start">
                                        <v-col cols="12" sm="3" md="3" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: true }" style="max-width: 100%;">
                                                <v-autocomplete ref="autocomplete" id="departamento" v-model="datosAfiliado.departamento" label="Departamento" 
                                                    dense outlined :error-messages="errors" :items="opcionesDepartamento" 
                                                    @click="listarDepartamento()" :menu-props="menuPropsP">
                                                </v-autocomplete>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3" md="3" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: datosAfiliado.departamento !== '' && datosAfiliado.departamento !== '00' }" style="max-width: 100%;">
                                                <v-autocomplete id="municipio" v-model="datosAfiliado.municipio" label="Ciudad" dense outlined :error-messages="errors" 
                                                    :items="opcionesMunicipio" @click="listarMunicipio()" :menu-props="menuPropsP"
                                                    :disabled="datosAfiliado.departamento === '' || datosAfiliado.departamento === '00'" @change="updateNombreMunicipio">
                                                </v-autocomplete>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: true }">
                                                <v-text-field ref="refDireccionAfiliado" v-model="datosAfiliado.direccion" :error-messages="errors" :disabled="datosAfiliado.municipio === '' || datosAfiliado.municipio === null"
                                                    @click="abrirDialogoDireccion()" @keydown.enter="abrirDialogoDireccion()" label="Dirección" readonly dense outlined>
                                                </v-text-field>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    <!--campos: celular, télefono y correo-->
                                    <v-row class="pr-3" align="start">
                                        <v-col cols="12" sm="3" md="3" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" name="celular" :rules="{
                                                required: true, numeric: true, digits: 10, regex: '^(30|31|32|33|34|35|36|37|38|39)\\d{8}$'}">
                                                <v-text-field v-model="datosAfiliado.numeroCelular" :error-messages="errors" :counter="10"
                                                    label="Celular" dense outlined maxlength="10">
                                                </v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3" md="3" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" name="telefono" :rules="{ numeric: true, digits: 10,
                                                regex: '^(601|602|604|605|606|607|608)\\d{7}$'}">
                                                <v-text-field v-model="datosAfiliado.numeroTelefono" :error-messages="errors" :counter="10"
                                                    label="Teléfono" dense outlined maxlength="10">
                                                </v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: true, validEmail: true }">
                                                <v-text-field v-model="datosAfiliado.email" label="Correo" dense outlined :error-messages="errors">
                                                </v-text-field>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    <!--campos: régimen, nivel y aseguradora-->
                                    <v-row class="pr-3" align="start">
                                        <v-col cols="12" sm="3" md="3" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: true }" style="max-width: 100%;">
                                                <v-select ref="selectRegNiv" v-model="datosAfiliado.regimenValue" :error-messages="errors" :items="regimenAfiliados"
                                                    :menu-props="menuPropsR" label="Régimen" dense outlined>
                                                </v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="3" md="3" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: true }" style="max-width: 100%;">
                                                <v-select v-model="datosAfiliado.nivel" :items="nivelAfiliados" label="Nivel"
                                                    :menu-props="menuPropsR" :error-messages="errors" dense outlined>
                                                </v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: true }" style="max-width: 100%;">
                                                <v-autocomplete ref="autoAseguradora" v-model="datosAfiliado.codigoAseguradora" :items="listaAseguradoras" dense
                                                    outlined :error-messages="errors" label="Aseguradora" @click="listarAseguradoras()" 
                                                    :disabled="aseguradoraLlena === true" :menu-props="menuPropsA">
                                                </v-autocomplete>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    <!--campos: contraseña y botón registrar-->
                                    <v-row class="pr-3" align="start">
                                        <v-col cols="12" sm="3" md="3" class="pr-0 pt-0 pb-0">
                                            <v-tooltip top color="#1867c0" v-model="tooltip">
                                                <template v-slot:activator="{ attrs }">
                                                    <validation-provider v-slot="{ errors }" :rules="{ required: true, min: 6, numeric: true }" >
                                                        <v-text-field v-model="datosAfiliado.contrasena" label="Contraseña" maxlength="6" dense outlined @paste.prevent
                                                            v-bind="attrs" :type="show1 ? 'text' : 'password'" :append-icon="show1 ? 'visibility' : 'visibility_off'"
                                                            @click:append="show1 = !show1" required :error-messages="errors" @keydown.space.prevent :counter="6"
                                                            @click="tooltip = !tooltip" @focus="tooltip = false" @blur="tooltip = false">
                                                        </v-text-field>
                                                    </validation-provider>
                                                </template>
                                                <span>
                                                    La contraseña debe estar <br> compuesta por 6 números.
                                                    <br><em>Ejemplo: 135796</em>
                                                </span>
                                            </v-tooltip>
                                        </v-col>
                                        <v-col cols="12" sm="3" md="3" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: true, numeric: true, contrasenaValida: true }" ref="confirmarContrasena">
                                                <v-text-field v-model="datosAfiliado.confirmarContrasena" label="Confirmar contraseña" :counter="6"
                                                    :type="show2 ? 'text' : 'password'" :append-icon="show2 ? 'visibility' : 'visibility_off'" maxlength="6" @keypress="validarLetra" @click="tooltip = false"
                                                    @click:append="show2 = !show2" required dense outlined :error-messages="errors" @keydown.space.prevent :disabled="datosAfiliado.contrasena === '' || datosAfiliado.contrasena === null">
                                                </v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6" class="pr-0 pt-0 pb-0">
                                            <PoliticaPrivacidad/>
                                            <div class="d-flex justify-end">
                                                <v-btn class="boton-actualizar" depressed :disabled="invalid" @click="menorDeEdad === false ? dialogoTerminos = true : abrirDialogoRL()">
                                                    Registrar
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </validation-observer>
                        </v-container>
                    </v-card-text>
                </v-card>

                <!-- Dialogo que usa el fórmulario del componente AddressForm.vue-->
                <v-dialog v-model="componenteAddress" persistent transition="dialog-bottom-transition" max-width="50rem">
                    <keep-alive>
                        <component 
                            :is="formularioDireccion.component"
                            :direccion="direccionSelecionada"
                            :municipio="municipioSeleccionado"
                            :idMunicipio="datosAfiliado.municipio"
                            :nombreBarrio="barrioSelecionado"
                            :nombreBarrioOpcional="barrioOpcional"
                            :titulo="formularioDireccion.title"
                            @close-address="componenteAddress = false"
                            @direccion-guardada="actualizarDireccion"
                            ref="addressComponent">
                        </component>
                    </keep-alive>
                </v-dialog>

            </div>

            <!-- Diálogo de Términos y Condiciones -->
            <v-dialog v-model="dialogoTerminos" max-width="39rem" scrollable persistent>
                <v-card>
                    <v-card-title class="headline justify-center">Política de privacidad y datos personales</v-card-title>
                    <v-card-text>
                        <div>
                            <p style="color: black;">
                                La Cooperativa de Hospitales de Antioquia cuya sigla es COHAN, es una entidad sin ánimo 
                                de lucro con reconocimiento de personería jurídica según Resolución no. 2380, del 28 de 
                                Octubre de 1983 e inscrita en la Cámara de Comercio el 6 de febrero de 1997, identificada 
                                con NIT 890.985.122-6, con domicilio en la Carrera 48 No. 24 – 104 de la ciudad de Medellín 
                                cuyo objeto general es promover el desarrollo integral de sus asociados, y a través de ellos 
                                la promoción de salud en la comunidad.
                                <br>
                                <br>
                                En todas las actuaciones de la Cooperativa, a través de sus unidades de negocio y por todos 
                                los medios en los cuales se solicita información, físico, electrónico, virtual, acceso telefónico, 
                                vía web, entre otros, está comprometida con el respeto de los derechos de todos los titulares 
                                de los datos personales que estén bajo su custodia y sometidos a los tratamientos adelante 
                                informados. Por esto se adopta la presente Política, la cual, es de obligatorio cumplimiento 
                                y aplicación en todas las actividades que involucren el tratamiento de datos personales.
                                <br>
                                <br>
                                En consecuencia, COHAN comunica a los grupos de interés con los cuales se relaciona la 
                                Política de Privacidad que ha sido adoptada para dar cumplimiento a lo dispuesto en el 
                                régimen legal vigente contenido en las Leyes Estatutarias 1581 de 2012 sobre Protección 
                                de Datos Personales y 1266 de 2008 sobre Habeas Data Financiero, en lo pertinente.
                                <br>
                                <br>
                                En cumplimiento de las facultades estatutarias y legales, COHAN trata datos personales de 
                                sus grupos de interés en ejecución de su objeto social consistente principalmente en la 
                                adquisición, comercialización y suministro de medicamentos, dispositivos médicos e 
                                insumos hospitalarios, así como la prestación de servicios farmacéuticos a los usuarios de 
                                sus clientes, asociados o no, como cooperativa multiactiva del sector de provisión de bienes 
                                y servicios en salud, posibilitando un proceso de dispensación de medicamentos hacia un 
                                modelo propio que incluye acciones integrales tanto en el ámbito ambulatorio como 
                                intrahospitalario. Igualmente, para estudiantes y participantes del servicio de formación, 
                                capacitación y educación continua que ofrece a través de su unidad de negocios Politécnico. 
                                Lo anterior sin perjuicio de los tratamientos de datos personales vinculados a otros fines del 
                                cooperativismo.
                                <br>
                                <br>
                                Los datos personales en custodia de COHAN, en su condición de Responsable y/o 
                                Encargado según el caso, serán tratados cumpliendo los principios y regulaciones previstas 
                                en las leyes colombianas y las buenas prácticas aplicables al régimen de protección de 
                                datos personales. Así mismo, cumpliendo las normas vigentes, se informa que los datos
                                personales podrán ser tratados directamente y/o a través de terceros, en condición de 
                                encargados del tratamiento de datos, en centros de datos ubicados dentro o fuera de 
                                Colombia, en territorios como Estados Unidos, Europa, Latinoamérica u otras regiones.
                                <br>
                                <br>
                                En consecuencia, serán tratados conforme las siguientes finalidades de carácter general:
                                <br>
                                <br>
                                <ul>
                                    <li>
                                        Prestar servicios de: comercialización de medicamentos e insumos hospitalarios a 
                                        clientes asociados y no asociados, prestación de servicios farmacéuticos mediante 
                                        la dispensación de medicamentos a los usuarios y pacientes de sus clientes; para la 
                                        prestación de servicios de formación y capacitación a estudiantes y participantes, y 
                                        demás servicios incluidos en el desarrollo de su objeto.
                                    </li>
                                    <li>
                                        Cumplir con las obligaciones y/o compromisos derivados de las relaciones, 
                                        contractuales o no, existentes con sus grupos de interés.
                                    </li>
                                    <li>
                                        Gestionar las relaciones jurídicas, comerciales o no, originadas en la adquisición de 
                                        productos y/o insumos de carácter hospitalario, sin perjuicio de otros servicios para 
                                        los cuales este habilitada por los estatutos y la ley en el marco de la filosofía 
                                        asociativa de esta cooperativa.
                                    </li>
                                    <li>
                                        Dar cumplimiento a las obligaciones legales y contractuales que involucren datos 
                                        personales de sus grupos de interés, sin limitarse a los propios de asociados, 
                                        clientes, pacientes y beneficiarios.
                                    </li>
                                    <li>
                                        Comunicar a sus grupos de interés, a través de canales tradicionales y/o virtuales, 
                                        información comercial relacionada con la oferta de productos, servicios, eventos, 
                                        programas, publicaciones, actualidad del sector, información empresarial, educación, 
                                        entre otra relacionada o conexas con su capacidad estatutaria, contractual y/o legal, 
                                        sea que se realice de manera directa o no.
                                    </li>
                                    <li>
                                        Suministrar información a las autoridades y/o cooperar con estas cuando sea 
                                        requerida para tal finalidad.
                                    </li>
                                    <li>
                                        Gestionar la seguridad de las personas, bienes y activos de información en custodia 
                                        de la organización.
                                    </li>
                                    <li>
                                        Desplegar hacia sus grupos de interés actividades de responsabilidad social 
                                        empresarial.
                                    </li>
                                    <li>
                                        Administrar la relación comercial y relacionamiento con los grupos de interés.
                                    </li>
                                    <li>
                                        Gestionar el crédito, cartera y cobranza originada en las relaciones contractuales de 
                                        orden crediticias con terceros clientes y/o proveedores.
                                    </li>
                                    <li>
                                        Gestionar los riesgos originados en la celebración de los contratos con terceros, a 
                                        efectos de prevenir daños económicos propios o a terceros.
                                    </li>
                                    <li>
                                        Administrar y gestionar la relación académica que surge de su unidad de servicios, 
                                        Politécnico COHAN, a través de la oferta de cursos y programas de educación para 
                                        el sector salud.
                                    </li>
                                    <li>
                                        Cumplir con las regulaciones del sector expedidas por las autoridades competentes.
                                    </li>
                                </ul>
                                <br>
                                En el proceso de suministro y servicios de dispensación que presta esta organización, y 
                                acorde con los datos personales recolectados y tratamiento a realizar, en el respectivo aviso 
                                de privacidad se informarán de forma previa las finalidades particulares; nombre o razón 
                                social y datos de contacto del Responsable o Encargado del tratamiento, si el tratamiento 
                                se efectuará por un encargado, caso en el que tal encargado estará bajo la dirección de 
                                COHAN; los derechos que le asisten al titular y los mecanismos dispuestos por esta 
                                organización para dar a conocer la Política de Privacidad.
                                Cualquier persona que haga parte de uno de los grupos de interés, en su condición de titular 
                                o legítimamente autorizado, en relación con tratamiento de sus datos personales tiene 
                                derecho a:
                                <br>
                                <br>
                                <ul>
                                    <li>
                                        Ejercer su derecho de Habeas Data consistente en conocer, actualizar, rectificar su 
                                        información de carácter personal. También podrá oponerse y cancelar sus datos e 
                                        información personal en aquellos casos en que ello proceda.
                                    </li>
                                    <li>
                                        Evidenciar la existencia del consentimiento otorgado, salvo que exista autorización 
                                        legal para el tratamiento o el mismo se realice en el marco de un contrato.
                                    </li>
                                    <li>
                                        Ejercer las acciones que la ley reconoce en materia de protección de datos 
                                        personales y habeas data.
                                    </li>
                                </ul>
                                <br>
                                Para el ejercicio del Habeas Data, el titular del dato personal o quien demuestre un legítimo 
                                interés conforme lo señalado en la normatividad vigente, podrá hacerlo a través del siguiente 
                                correo electrónico: habeasdata@cohan.org.co o dirigiendo una comunicación física a la 
                                siguiente dirección en la ciudad de Medellín: Carrera 48 No. 24-104.
                                <br>
                                <br>
                                Quien ejerza el habeas data deberá suministrar con precisión los datos de contacto 
                                solicitados para efecto de tramitar, atender y responder su solicitud y desplegar las cargas 
                                para el ejercicio de sus derechos, en especial los siguientes: a) Nombres completos del 
                                titular. b) Documento de identidad (original). c) Documento de identidad (copia legible).
                                d) Dirección de residencia. e) Ciudad de residencia. f) Teléfono fijo. g) Teléfono móvil.
                                h) Correo electrónico. i) Descripción del requerimiento sobre el o los derechos que desea 
                                acceder referente al tratamiento de los datos personales.
                            </p>
                        </div>
                    </v-card-text>
                    <v-card-actions  class="d-flex justify-end">
                        <div>
                            <v-btn class="mr-2" color="#0d47a1" rounded outlined @click="rechazarTerminos">Rechazar</v-btn>
                            <v-btn rounded @click="aceptarTerminos" color="primary">Aceptar</v-btn>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            
            <!-- Diálogo para recordar la importancia de la autorización médica -->
            <v-dialog v-model="dialogoGuardado" max-width="22rem" scrollable close-delay persistent>
                <v-card>
                    <v-card-text class="mt-4 pb-2">
                        <div>
                        <p style="color: black;" class="ma-0 text-center">
                            Registro completado. por favor revise su correo para activar la cuenta y continuar.
                        </p>
                        </div>
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center pb-3">
                        <v-btn color="#0d47a1" small rounded outlined @click="redirectLogin">CONTINUAR</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Pie de página -->
            <div class="pieDePaginaContainer">
                <div class="d-flex justify-center mt-0">
                    <span class="pieDePagina" style="font-size: 15px">©</span>
                    <span>&nbsp;</span>
                    <span class="pieDePagina">{{ new Date().getFullYear() }} COHAN - Todos los derechos reservados.</span>
                    <PoliticaPrivacidad/>
                </div>
            </div>

            <!-- dialogo para ingresar los datos del representante legal del paciente menor de edad -->
            <v-dialog v-model="dialogoRepresentanteLegal" persistent transition="dialog-bottom-transition" max-width="30.5rem">
                <v-card>
                    <v-card-title class="fondoDialog d-flex justify-center">
                        <span class="text-h6">Datos del responsable / acudiente</span>
                    </v-card-title>
                    <v-container>
                        <v-card-text class="mt-0 pt-0">
                            <validation-observer ref="observerRL" v-slot="{ invalid }">
                                <v-form class="mt-3" lazy-validation>
                                     <!--campos: tipo y número de identificación del representante legal -->
                                     <v-row class="pr-3" align="start">
                                        <v-col cols="12" sm="6" md="6" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: true, }" ref="tiposDeDocumentoRL" style="max-width: 100%;">
                                                <v-select ref="selectRL" id="tipoDocumentoRL" v-model="representanteLegal.tipoDocumento" :items="tipDocuRL" outlined  
                                                    :menu-props="menuPropsRL" :error-messages="errors" dense label="Tipo de identificación" 
                                                    @click="listarTipDocuRL()" @focus="tipDocuFieldSize()">
                                                </v-select>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: true, numericRL: true, identificacion: 6, allZero: true }">
                                                <v-text-field v-model="representanteLegal.numeroDocumento" :error-messages="errors"
                                                    label="N° de documento" dense outlined maxlength="11" @keypress="validarLetra" @paste="handlePaste($event)">
                                                </v-text-field>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    <!--campos: primer y segundo nombre del representante legal -->
                                    <v-row class="pr-3" align="start">
                                        <v-col cols="12" sm="6" md="6" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: true, }">
                                                <v-text-field v-model="representanteLegal.primerNombre" :error-messages="errors"
                                                    label="Primer nombre" dense outlined>
                                                </v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6" class="pr-0 pt-0 pb-0">
                                            <validation-provider>
                                                <v-text-field v-model="representanteLegal.segundoNombre" label="Segundo nombre" dense
                                                    outlined>
                                                </v-text-field>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    <!--campos: primer y segundo apellido del representante legal -->
                                    <v-row class="pr-3" align="start">
                                        <v-col cols="12" sm="6" md="6" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: true, validLastName: true }">
                                                <v-text-field v-model="representanteLegal.primerApellido" :error-messages="errors"
                                                    label="Primer apellido" dense outlined>
                                                </v-text-field>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" :rules="{ validLastName: true }">
                                                <v-text-field v-model="representanteLegal.segundoApellido" 
                                                    label="Segundo apellido" dense outlined :error-messages="errors">
                                                </v-text-field>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    <!--campos: celular y correo del representante legal -->
                                    <v-row class="pr-3" align="start">
                                        <v-col cols="12" sm="6" md="6" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: true }">
                                                <v-autocomplete ref="selectParentesco" id="parentescoRL" v-model="representanteLegal.parentesco" dense outlined
                                                    :items="tiposParentescos" :menu-props="menuPropsParentesco" :error-messages="errors" label="Parentesco"
                                                    @focus="relationshipFieldSize()">
                                                </v-autocomplete>
                                            </validation-provider>
                                        </v-col>
                                        <v-col cols="12" sm="6" md="6" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" name="celular" :rules="{
                                                required: true, numeric: true, digits: 10, regex: '^(30|31|32|33|34|35|36|37|38|39)\\d{8}$'}">
                                                <v-text-field v-model="representanteLegal.numeroCelular" :error-messages="errors" :counter="10"
                                                    label="Celular" dense outlined maxlength="10">
                                                </v-text-field>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    <!--campo del parentesco del representante legal con el paciente menor de edad -->
                                    <v-row class="pr-3" align="start">
                                        <v-col cols="12" sm="12" md="12" class="pr-0 pt-0 pb-0">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: true, validEmail: true }">
                                                <v-text-field v-model="representanteLegal.email" label="Correo" dense outlined :error-messages="errors">
                                                </v-text-field>
                                            </validation-provider>
                                        </v-col>
                                    </v-row>
                                    <!--campo botón de confirmación de los datos del representante legal-->
                                    <v-row class="pr-3" align="start">
                                        <v-col cols="12" sm="12" md="12" class="pr-0 pt-0 pb-0">
                                            <PoliticaPrivacidad/>
                                            <div class="d-flex justify-end">
                                                <v-btn class="mr-2" color="#0d47a1" rounded outlined @click="cerrarDialogoRL()">
                                                    Cancelar
                                                </v-btn>
                                                <v-btn class="boton-actualizar" depressed :disabled="invalid" @click="dialogoTerminos = true">
                                                    Confirmar
                                                </v-btn>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </validation-observer>
                        </v-card-text>
                    </v-container>
                </v-card>
            </v-dialog>

            <!-- Contenedor para mostrar los mensajes de error -->
            <div v-if="mostrarNotificacion" :class="['notificacion', tipoNotificacion]">
                <span><v-icon :color="tipoNotificacionColor" :class="iconoNotificacionClase" class="rotate-animation-notification size">{{ iconoNotificacion }}</v-icon></span>
                <span>{{ mensajeNotificacion }}</span>
            </div>

            <!-- Mensaje de alerta para cuando hay una validación en proceso -->
            <template>
                <div v-if="validateProgress" class="overlay">
                    <v-alert class="notificationValidation" persistent transition="dialog-bottom-transition">
                        <v-icon class="d-flex justify-center rotate-animation" color="white" large>rotate_right</v-icon>
                        <span class="d-flex justify-center">Registro en proceso</span>
                    </v-alert>
                </div>
            </template>

        </v-main>
    </v-app>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from "vuex";
import { required, regex, digits, min, email, numeric } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';
import CryptoJS from "crypto-js";
import PoliticaPrivacidad from '../../../components/PoliticaPrivacidad.vue';
import AddressForm from "../../../components/AddressForm.vue";

setInteractionMode('eager')
extend('required', {
    ...required,
    message: 'Este campo es requerido.',
});
extend('min', {
    ...min,
    message: 'Requiere 6 dígitos ',
});
extend('digits', {
    ...digits,
    message: 'Número Incorrecto.',
});
extend('regex', {
    ...regex,
    message: 'Formato Incorrecto.',
});
extend('validEmail', {
    ...email,
    message: 'El correo electrónico debe ser válido.'
});
extend('numeric', {
    ...numeric,
    message: 'Ingrese solo números.'
});
extend('identificacion', {
    ...min,
    message: 'Mínimo 6 dígitos '
});
extend('numericRL', {
    ...numeric,
    message: 'Este campo solo permite números'
});
extend('allZero', {
  validate: value => {
    if (!/\d*[1-9]\d*/.test(value)) {
      return false;
    }
    return true;
  },
  message: 'Formato incorrecto.'
});
extend('validLastName', {
  validate: value => {
    const lastName = value.trim();
    if (lastName.length < 3) {
      return false;
    }
    if (!/^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ ]+$/.test(lastName)) {
      return false;
    }
    return true;
  },
  message: 'Apellido inválido.'
});

export default {
    name: 'RegistroAfiliado',
    components: {
        ValidationProvider,
        ValidationObserver,
        PoliticaPrivacidad,
        AddressForm,
    },
    data() {
        return {
            menu: false,
            activePicker: null,
            menu2: false,
            nombreMunicipio: '',
            datosAfiliado: {
                primerNombre: '',
                segundoNombre: '',
                primerApellido: '',
                segundoApellido: '',
                generoValue: '',
                genero: '',
                tipoDocumento: '',
                numeroDocumento: null,
                estadoAfiliado: 'A',
                fechaNacimiento: '',
                codigoPais: '170',
                departamento: '',
                municipio: '',
                ciudad: '',
                email: '',
                actualizoUsuario: '',
                numeroTelefono: '',
                numeroCelular: '',
                codigoAseguradora: '',
                regimenValue: '',
                regimen: '',
                nivel: '',
                tiposDireccion: null,
                direccion: '',
                contrasena: '',
                confirmarContrasena: '',
            },
            regimenAfiliados: [
                { text: "Contributivo", value: "CONTRIBUTIVO" },
                { text: "Subsidiado", value: "SUBSIDIADO" },
                { text: "Especial", value: "ESPECIAL" },
                { text: "Excepción", value: "EXCEPCION" },
                { text: "Otro régimen", value: "OTRO_REGIMEN" },
                { text: "Sin régimen", value: "SIN_REGIMEN" },
            ],
            regimenMapping: {
                "CONTRIBUTIVO": "RC",
                "SUBSIDIADO": "RS",
                "ESPECIAL": "RE",
                "EXCEPCION": "EX",
                "OTRO_REGIMEN": "OR",
                "SIN_REGIMEN": "SR"
            },
            generoAfiliados: [
                { text: "Sin dato", value: "NO_DATA" },
                { text: "Masculino", value: "MALE" },
                { text: "Femenino", value: "FEMALE" },
                { text: "Indeterminado", value: "INDETERMINATE" },
            ],
            generoMapping: {
                "NO_DATA": "N",
                "MALE": "M",
                "FEMALE": "F",
                "INDETERMINATE": "I"
            },
            tiposDoc: [],
            opcionesDepartamento: [],
            opcionesMunicipio: [],
            barrioSelecionado: '',
            listaAseguradoras: [],
            nivelAfiliados: [
                { text: "1", value: 1 },
                { text: "2", value: 2 },
                { text: "3", value: 3 },
                { text: "4", value: 4 },
                { text: "5", value: 5 },
                { text: "6", value: 6 },
            ],
            nivelMapping: {
                1: "LOW_LOW",
                2: "LOW",
                3: "MEDIUM_LOW",
                4: "MEDIUM",
                5: "MEDIUM_HIGH",
                6: "HIGH"
            },
            rules: {
                agregarRules: [
                    v => !!v || "Obligatorio",
                ],
            },
            camposDireccionUrbanaLlenos: false,
            camposDireccionRuralLlenos: false,
            show1: false,
            show2: false,
            dialogoTerminos: false,
            checkboxPrivacidad: null,
            tituloFormulario: '',
            documentoActualizado: null,
            estadoRegistro: false,
            aseguradoraLlena: false,
            dialogoGuardado: false,
            contrasenaActual: '',
            tooltip: false,
            menuProps: { offsetY: true, maxHeight: 170, maxWidth: null },
            menuPropsP: { offsetY: true, maxHeight: 170, maxWidth: null },
            menuPropsR: { offsetY: true, maxHeight: 105, maxWidth: null },
            menuPropsA: { offsetY: true, maxHeight: 105, maxWidth: null },
            menuPropsRL: { offsetY: true, maxHeight: 200, maxWidth: null },
            menuPropsParentesco: { offsetY: true, maxHeight: 140, maxWidth: null },
            screenSmaller: false,
            dialogoRepresentanteLegal: false,
            representanteLegal: {
                tipoDocumento: '',
                numeroDocumento: null,
                primerNombre: '',
                segundoNombre: '',
                primerApellido: '',
                segundoApellido: '',
                parentesco: null,
                numeroCelular: null,
                email: '',
                nombreCompleto: '',
            },
            tiposParentescos: [
                { text: "Madre", value: 0 },
                { text: "Padre", value: 1 },
                { text: "Abuela", value: 2 },
                { text: "Abuelo", value: 3 },
                { text: "Tía", value: 4 },
                { text: "Tío", value: 5 },
                { text: "Hermano", value: 6 },
                { text: "Hermana", value: 7 },
                { text: "Tutor", value: 8 },
            ],
            tipDocuRL: [],
            menorDeEdad: null,
            validateProgress: false,
            mostrarNotificacion: false,
            mensajeNotificacion: '',
            tipoNotificacion: '',
            tipoNotificacionColor: '',
            iconoNotificacion: '',
            iconoNotificacionClase: '',
            tiposDireccion: '',
            tipoDireccion: '',
            tiposVias: ["AVENIDA CALLE", "AVENIDA CARRERA", "AUTOPISTA", "AVENIDA", "ANILLO VIAL", "CALLE", "CALLEJÓN", "CARRERA", "CARRETERA", "CIRCUNVALAR", "DIAGONAL", "TRANSVERSAL"],
            barrio: '',
            opcionesBarrio: [],
            barrioOpcional: '',
            formularioDireccion: {
                component: 'AddressForm',
                title: 'Dirección',
            },
            componenteAddress: false,
            direccionSelecionada: '',
            municipioSeleccionado: '',
            datoAdicionalDireccion: '',
        }
    },
    watch: {
        menu(val) { val && setTimeout(() => (this.activePicker = 'YEAR')) },
        'datosAfiliado.departamento': {
            handler: 'onDepartamentoChange',
            deep: true,
        },
        'datosAfiliado.contrasena': function (contrasena) {
            this.contrasenaActual = contrasena;
            extend('contrasenaValida', {
                validate: (value) => value === this.contrasenaActual,
                message: 'Las contraseñas no coinciden'
            });
            if (contrasena !== this.datosAfiliado.confirmarContrasena ) {
                this.datosAfiliado.confirmarContrasena = '';
                const confirmarContrasena = this.$refs.confirmarContrasena;
                if (confirmarContrasena) {
                    confirmarContrasena.reset();
                }
            }
        },
        'datosAfiliado.tipoDocumento': function() {
            const tiposValidos = ['TI', 'CN', 'CNV', 'MS', 'NV', 'RC'];
            if (tiposValidos.includes(this.datosAfiliado.tipoDocumento.value)) {
                this.menorDeEdad = true;
            } else {
                this.menorDeEdad = false;
            }
        },
    },
    /**
     * Método que se ejecuta cuando el componente es montado en el DOM.
     * 
     * Este método obtiene el parámetro de consulta 'data' de la URL actual y lo parsea como JSON.
     * Si el objeto 'data' contiene la propiedad 'numeroDocumento', se llama al método 'llenarFormulario' del componente 
     * y se pasa el objeto 'data' como argumento. Si no se encuentra el parámetro 'data' o no contiene la propiedad 'numeroDocumento', 
     * se establece el título del formulario en "Ingrese sus datos".
     */
    mounted() {
        const hash = window.location.hash;
        const queryString = hash.substring(hash.indexOf('?'));
        const urlParams = new URLSearchParams(queryString);
        const jsonData = urlParams.get('data');
        if (jsonData) {
            const data = JSON.parse(jsonData);
            if (data && data.numeroDocumento) {
                this.llenarFormulario(data);
            } else {
                this.tituloFormulario = "Ingrese sus datos";
            }
        }
        this.checkScreenSize();
        window.addEventListener('resize', this.checkScreenSize);
    },
    computed: {
        ...mapState(["auth", "notify"]),
    },
    methods: {
        ...mapMutations(["showSuccess", "showError"]),
        /** Configura el tamaño del campo para el tipo de documento del representante legal */
        tipDocuFieldSize() {
            this.menuPropsRL.maxWidth = this.$refs.selectRL.$el.offsetWidth;
        },
        /** Configura el tamaño del campo para el parentesco del representante legal */
        relationshipFieldSize() {
            this.menuPropsParentesco.maxWidth = this.$refs.selectParentesco.$el.offsetWidth;
        },
        /**
         * Método asincrónico para llenar el formulario con los datos recibidos.
         * @param {Object} data - Los datos para llenar el formulario.
         */
        async llenarFormulario(data) {
            if (data.direccion !== undefined) {
                this.tituloFormulario = "Actualice sus datos";
                const almohadilla = data.direccion.replace(/_/g, '#');
                data.direccion = almohadilla;
                await this.listarTiposDeDocumentos();
                this.datosAfiliado.tipoDocumento = this.tiposDoc.find(doc => doc.tipoDocumento === data.tipoDocumento);
                this.datosAfiliado.numeroDocumento = data.numeroDocumento;
                this.datosAfiliado.primerNombre = data.primerNombre;
                this.datosAfiliado.segundoNombre = data.segundoNombre;
                this.datosAfiliado.primerApellido = data.primerApellido;
                this.datosAfiliado.segundoApellido = data.segundoApellido;
                const generoObject = this.generoAfiliados.find(genero => genero.value === data.genero);
                this.datosAfiliado.generoValue = generoObject ? generoObject.value : '';
                this.datosAfiliado.numeroTelefono = (/^\d{10}$/.test(data.numeroTelefono) && /^(601|602|604|605|606|607|608)/.test(data.numeroTelefono)) ? data.numeroTelefono : null;
                this.datosAfiliado.numeroCelular = (/^\d{10}$/.test(data.numeroCelular) && /^(30|31|32|33|34|35|36|37|38|39)/.test(data.numeroCelular)) ? data.numeroCelular : null;
                this.datosAfiliado.email = data.email &&  /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(data.email) ? data.email.toLowerCase() : '';
                this.datosAfiliado.fechaNacimiento = data.fechaNacimiento;
                const nivelMapping = {
                    "LOW_LOW": 1,
                    "LOW": 2,
                    "MEDIUM_LOW": 3,
                    "MEDIUM": 4,
                    "MEDIUM_HIGH": 5,
                    "HIGH": 6,
                };
                const nivelObject = this.nivelAfiliados.find(nivel => nivel.value === nivelMapping[data.nivel]);
                this.datosAfiliado.nivel = nivelObject ? nivelObject.value : '';
                const regimenObject = this.regimenAfiliados.find(regimen => regimen.value === data.regimen)
                this.datosAfiliado.regimenValue = regimenObject ? regimenObject.value : '';
                await this.listarDepartamento();
                let departamentoActual = this.opcionesDepartamento.find(departamento => departamento.value === data.idDepartamento);
                if (typeof departamentoActual === 'object') {
                    this.datosAfiliado.departamento = departamentoActual.value;
                } else {
                    this.datosAfiliado.departamento = '';
                }
                await this.listarMunicipio();
                let municipioActual = this.opcionesMunicipio.find(municipio => municipio.municipio === data.ciudad);
                if (typeof municipioActual === 'object') {
                    this.datosAfiliado.municipio = municipioActual.value;
                    this.nombreMunicipio = municipioActual.text;
                } else {
                    this.datosAfiliado.municipio = '';
                    this.nombreMunicipio = '';
                }
                this.barrioSelecionado = data.barrio != null ? data.barrio : '';
                if (this.datosAfiliado.municipio === '' || this.barrioSelecionado === '') {
                    this.datosAfiliado.direccion = '';
                } else {
                    this.datosAfiliado.direccion = (data.direccion && typeof data.direccion === 'string')
                    ? (function() {
                        const words = data.direccion.trim().split(/\s+/);
                        const tiposVias = ["AVENIDA CALLE", "AVENIDA CARRERA", "AUTOPISTA", "AVENIDA", "ANILLO VIAL", "CALLE", "CALLEJÓN", "CARRERA", "CARRETERA", "CIRCUNVALAR", "DIAGONAL", "TRANSVERSAL"];
                        const palabrasPermitidas = [...tiposVias, "SECTOR", "VEREDA"];
                        const primerPalabra = words[0] && palabrasPermitidas.find(palabra => words[0].startsWith(palabra));
                        if (!primerPalabra || words.length < 4) {
                            return '';
                        } else {
                            return data.direccion.trim();
                        }
                    })()
                    : '';
                }
                if (data.idAseguradora !== null && data.idAseguradora !== undefined) {
                    await this.listarAseguradoras();
                    let aseguradoraActual = this.listaAseguradoras.find(aseguradora => aseguradora.value === data.idAseguradora);
                    if (typeof aseguradoraActual === 'object') {
                        this.datosAfiliado.codigoAseguradora = aseguradoraActual.value;
                        this.aseguradoraLlena = true;
                    } else {
                        this.datosAfiliado.codigoAseguradora = '';
                        this.aseguradoraLlena = false;
                    }
                } else {
                    this.datosAfiliado.codigoAseguradora = '';
                    this.aseguradoraLlena = false;
                }
                this.estadoRegistro = data.estadoRegistro;
            } else {
                this.tituloFormulario = "Ingrese sus datos";
                await this.listarTiposDeDocumentos();
                this.datosAfiliado.tipoDocumento = this.tiposDoc.find(doc => doc.tipoDocumento === data.tipoDocumento);
                this.datosAfiliado.numeroDocumento = data.numeroDocumento;
                this.estadoRegistro = false;
                this.listarDepartamento();
                this.listarAseguradoras();
            }
            this.sizeList();
        },
        /**
         * Define el ancho de las listas
         */
        sizeList() {
            this.$nextTick(() => {
                this.menuProps.maxWidth = this.$refs.select.$el.offsetWidth;
                this.menuPropsP.maxWidth = this.$refs.autocomplete.$el.offsetWidth;
                this.menuPropsR.maxWidth = this.$refs.selectRegNiv.$el.offsetWidth;
                this.menuPropsA.maxWidth = this.$refs.autoAseguradora.$el.offsetWidth;
            });
        },
        /**
         * Método usado para abrir el dialogo que contiene las politicas de privacidad desde el texto políticas de privacidad.
         */
        abrirDialogoTerminosLink() {
            this.$root.$emit('abrirDialogo');
        },
        /**
         * Método usado para cerrar el dialogo que contiene las politicas de privacidad y aceptarlas.
         */
        aceptarTerminos() {
            this.dialogoRepresentanteLegal = false;
            this.checkboxPrivacidad = true;
            this.dialogoTerminos = false;
            this.guardarAfiliado();
        },
        /**
         * Método usado para cerrar el dialogo que contiene las politicas de privacidad y rechazarlas.
         */
        rechazarTerminos() {
            this.dialogoTerminos = false;
            this.checkboxPrivacidad = false;
        },
        /**
         * Recupera una lista de tipos de documentos desde el servidor y la almacena en la propiedad
         * 'tiposDoc' del componente.
         * @throws {Error} Si ocurre un error durante la solicitud al servidor.
         */
        async listarTiposDeDocumentos() {
            this.tiposDoc = [];
            try {
                const response = await this.$http.get(`msa-external/public/tipo-documento/list`);
                this.tiposDoc = response.data.map(item => ({
                    text: `${item.descripcion}`,
                    value: item.tipoDocumento,
                    tipoDocumento: item.tipoDocumento,
                }));
                    
            } catch (error) {
                this.manejarError(error);
            }
        },
        /**
         * Recupera una lista de aseguradoras desde el servidor y la almacena en la propiedad listaAseguradoras.
         * @throws {Error} Si ocurre un error durante la solicitud al servidor.
         */
        async listarAseguradoras() {
            this.listaAseguradoras = [];
            try {
                const response = await this.$http.get(`msa-external/public/aseguradora/list`);
                this.listaAseguradoras = response.data.map(item => ({
                    text: `${item.nombreAseguradora}`,
                    value: item.idAseguradora,
                }));
            } catch (error) {
                this.manejarError(error);
            }
        },
        /**
         * Obtiene la lista de departamentos basada en el país seleccionado y actualiza las opciones de departamento en el componente.
         * Las opciones se almacenan en el array 'opcionesDepartamento'.
         */
        async listarDepartamento() {
            this.opcionesDepartamento = [];
            try {
                const response = await this.$http.get(`msa-external/public/departamento/list`);
                const filterDepa = response.data.filter(item => item.idDepartamento !== '00');
                this.opcionesDepartamento = filterDepa.map(item => ({
                    text: `${item.departamento}`,
                    value: `${item.idDepartamento}`,
                }));
            } catch (error) {
                this.manejarError(error);
            }
        },
        /**
         * Método invocado cuando se produce un cambio en la selección del departamento.
         * Limpia la variable de municipio y llama al método para listar los municipios basados en el departamento seleccionado.
         */
        async onDepartamentoChange() {
            // Limpiar la variable de departamento al cambiar el país
            this.datosAfiliado.municipio = '';
            this.datosAfiliado.direccion = '';
            if (this.$refs.refDireccionAfiliado) {
                this.$refs.refDireccionAfiliado.reset()
            }
            // Llamar al método para listar departamentos
            await this.listarMunicipio();
        },
        /**
         * Obtiene la lista de municipios basada en el departamento seleccionado y actualiza las opciones de municipio en el componente.
         * Las opciones se almacenan en el array 'opcionesMunicipio'.
         */
        async listarMunicipio() {
            if (this.datosAfiliado.departamento !== '') {
                try {
                    const response = await this.$http.get(`msa-external/public/ciudad/list/${this.datosAfiliado.departamento}`);
                    this.opcionesMunicipio = response.data.map(item => ({
                        text: `${item.ciudad}`,
                        value: `${item.idCiudad}`,
                        municipio: item.ciudad,
                    }));
                } catch (error) {
                    this.manejarError(error);
                }
            }
        },
        /**
         * Método que se ejecuta cuando se cambia el valor de la propiedad this.datosAfiliado.municipio
         * Actualiza el valor de la propiedad this.nombreMunicipio de acuerdo al valor del id del municipio definido en this.datosAfiliado.municipio
         * y limpia el valor en la propiedad this.datosAfiliado.direccion
         */
        updateNombreMunicipio() {
            const selectedMunicipio = this.opcionesMunicipio.find(option => option.value === this.datosAfiliado.municipio);
            if (selectedMunicipio) {
                this.nombreMunicipio = selectedMunicipio.text;
            } else {
                this.nombreMunicipio = '';
            }
            this.datosAfiliado.direccion = '';
        },
        /**
         * Obtiene la lista de barrios basada en el municipio seleccionado y actualiza las opciones de barrio en el componente.
         * Las opciones se almacenan en el array 'opcionesBarrio'.
         * @throws {Error} Si ocurre un error durante la solicitud al servidor.
         */
        async listarBarrio() {
            if (this.datosAfiliado.municipio !== '') {
                try {
                    const response = await this.$http.get(`msa-external/public/barrio/list/${this.datosAfiliado.municipio}`);
                    this.opcionesBarrio = response.data.map(item => ({
                        text: `${item.barrio}`,
                        value: `${item.barrio}`,
                        barrio: item.barrio,
                        id: item.idBarrio,
                    }));
                    this.opcionesBarrio.push({ text: 'OTRO', value: 'OTRO', barrio: 'OTRO', id: null });
                } catch (error) {
                    this.manejarError(error);
                }
            }
        },
        /**
         * Método para guardar la fecha seleccionada en el componente de selección de fecha.
         * @param {Date} date - La fecha seleccionada.
         */
        save(date) {
            // Se llama al método save del ref "menu" para guardar la fecha
            this.$refs.menu.save(date)
        },
        /**
         * Establece la propiedad en true cuando la pantalla tiene un ancho igual o menor a 959
         */
        checkScreenSize() {
            this.screenSmaller = window.innerWidth <= 959;
        },
        /**
         * Método asíncrono para guardar los datos del afiliado.
         * 
         * Realiza la validación de los campos del formulario y envía una solicitud POST al servidor para guardar los datos del afiliado.
         * Si la validación de los campos es exitosa, se construye un objeto con los datos del afiliado y se envía al servidor.
         * Si la respuesta del servidor indica éxito, se muestra un diálogo de confirmación.
         * Si la respuesta del servidor indica error, se muestra un mensaje de error en la consola.
         */
        async guardarAfiliado() {
            this.validateProgress = true;
            await this.listarBarrio();
            let barrioActual = this.opcionesBarrio.find(barrio => barrio.barrio === this.barrioSelecionado);
            if (typeof barrioActual === 'object') {
                this.idBarrio = barrioActual.id;
            } else {
                this.idBarrio = null;
            }
            if (this.$refs.form.validate()) {
                this.datosAfiliado.genero = this.generoMapping[this.datosAfiliado.generoValue];
                this.datosAfiliado.regimen = this.regimenMapping[this.datosAfiliado.regimenValue];
                const documento = this.datosAfiliado.tipoDocumento.value;
                const documentoNuevo = this.datosAfiliado.tipoDocumento;
                if (documento != null) {
                    this.documentoActualizado = documento;
                } else {
                    this.documentoActualizado = documentoNuevo;
                }

                let direccionCompleta = this.datosAfiliado.direccion;
                let nuevaDireccion;
                let indiceNumeral = direccionCompleta.indexOf('#');
                if (indiceNumeral !== -1) {
                    const barrioEscapado = this.barrioSelecionado.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                    const municipioEscapado = this.nombreMunicipio.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                    const regexBarrio = new RegExp(',\\s*' + barrioEscapado, 'i');
                    const regexMunicipio = new RegExp(',\\s*' + municipioEscapado, 'i');
                    let direccionU = direccionCompleta.replace(regexBarrio, '').replace(regexMunicipio, '');
                    nuevaDireccion = direccionU.trim();
                } else {
                    const municipioEscapado = this.nombreMunicipio.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
                    const regexMunicipio = new RegExp(',\\s*' + municipioEscapado, 'i');
                    let direccionR = direccionCompleta.replace(regexMunicipio, '');
                    nuevaDireccion = direccionR.trim();
                }
                if (nuevaDireccion.endsWith(',')) {
                    nuevaDireccion = nuevaDireccion.slice(0, -1); 
                }
                if (this.menorDeEdad === true) {
                    let nombreCompleto = `${this.representanteLegal.segundoNombre != '' ? this.representanteLegal.primerNombre.trim() + ' ' + this.representanteLegal.segundoNombre.trim() : this.representanteLegal.primerNombre.trim()} ${this.representanteLegal.segundoApellido != '' ? this.representanteLegal.primerApellido.trim() + ' ' + this.representanteLegal.segundoApellido.trim() : this.representanteLegal.primerApellido.trim()}`;
                    this.representanteLegal.nombreCompleto = nombreCompleto.toUpperCase();
                } else {
                    this.representanteLegal.nombreCompleto = ''
                }
                const esDireccionUrbana = this.tiposVias.some(opcion => nuevaDireccion.startsWith(opcion));
                let tipoDireccion;
                if (esDireccionUrbana) {
                    tipoDireccion = 'U'
                } else {
                    tipoDireccion = 'R'
                }
                const afiliado = {
                    idEmpresa: 1,
                    tipoDocumento: this.documentoActualizado,
                    numeroDocumento: this.datosAfiliado.numeroDocumento.trim().toUpperCase(),
                    primerNombre: this.datosAfiliado.primerNombre.toUpperCase(),
                    segundoNombre: this.datosAfiliado.segundoNombre !== '' ? this.datosAfiliado.segundoNombre.toUpperCase() : '',
                    primerApellido: this.datosAfiliado.primerApellido.toUpperCase(),
                    segundoApellido: this.datosAfiliado.segundoApellido !== '' ? this.datosAfiliado.segundoApellido.toUpperCase() : '',
                    genero: this.datosAfiliado.generoValue,
                    generoValue: this.datosAfiliado.genero,
                    tipoDireccion: tipoDireccion,
                    direccion: nuevaDireccion,
                    numeroTelefono: Number((this.datosAfiliado.numeroTelefono != '' && this.datosAfiliado.numeroTelefono != null) ? this.datosAfiliado.numeroTelefono : 0),
                    numeroCelular: Number(this.datosAfiliado.numeroCelular),
                    email: this.datosAfiliado.email.toUpperCase(),
                    fechaNacimiento: `${this.datosAfiliado.fechaNacimiento}T00:00:00-05:00`,
                    nivel: this.nivelMapping[this.datosAfiliado.nivel],
                    nivelValue: this.datosAfiliado.nivel,
                    regimen: this.datosAfiliado.regimenValue,
                    regimenValue: this.datosAfiliado.regimen,
                    idAseguradora: this.datosAfiliado.codigoAseguradora,
                    idDepartamento: this.datosAfiliado.departamento,
                    idCiudad: this.datosAfiliado.municipio,
                    idBarrio: this.idBarrio,
                    password: this.datosAfiliado.contrasena,
                    tratamientoDatos: this.checkboxPrivacidad,
                    estadoRegistro: this.estadoRegistro === undefined ? false : this.estadoRegistro,
                    tipoDocumentoResponsable: this.representanteLegal.tipoDocumento,
                    numeroDocumentoResponsable: this.representanteLegal.numeroDocumento,
                    nombreCompletoResponsable: this.representanteLegal.nombreCompleto,
                    parentesco: this.representanteLegal.parentesco,
                    numeroCelularResponsable: this.representanteLegal.numeroCelular != null ? Number(this.representanteLegal.numeroCelular) : null,
                    emailResponsable: this.representanteLegal.email.toUpperCase(),
                }
                this.$http.post(`msa-external/public/registrar`, afiliado)
                .then(({ data }) => {
                    if (data.status === 1) {
                        this.dialogoGuardado = true;
                    } else if (data.status === 0) {
                        console.log(data.status);
                    }
                    this.validateProgress = false;
                }).catch(err => {
                    this.manejarError(err);
                    this.validateProgress = false;
                    this.showError(err.response.data);
                });
            } else {
                this.validateProgress = false;
                this.showError("Complete todos los campos") 
            }
        },
        /**
         * Método para encriptar una contraseña utilizando el algoritmo AES.
         * 
         * @param {string} password - La contraseña a encriptar.
         * @param {string} claveSecreta - La clave secreta utilizada para la encriptación.
         * @returns {object} Un objeto que contiene el vector de inicialización (iv) y la contraseña encriptada.
         * @throws {Error} Error si ocurre algún problema durante la encriptación.
         */
        encriptarPassword(password, claveSecreta) {
            try {
                const clave = CryptoJS.enc.Utf8.parse(claveSecreta);

                const iv = CryptoJS.lib.WordArray.random(16);
                
                const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), clave, {
                    iv: iv,
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7
                });

                const encryptedBase64 = CryptoJS.enc.Base64.stringify(iv.concat(encrypted.ciphertext));
                
                return { iv: iv.toString(CryptoJS.enc.Base64), encryptedPassword: encryptedBase64 };
            } catch (error) {
                throw new Error("Error al encriptar la contraseña: " + error.message);
            }
        },
        /**
         * Valida que la tecla presionada sea un número.
         * Evita la entrada de caracteres no numéricos.
         * @param {Event} event - Evento de tecla presionada.
         */
        validarLetra(event) {
            const teclaPresionada = event.key
            if (teclaPresionada.match(/[^0-9]/)) {
                event.preventDefault();
            }
        },
        /**
         * Maneja el evento de pegado en un campo de entrada, permitiendo solo valores numéricos.
         * @param {Event} event El evento de pegado.
         */
        handlePaste(event) {
            event.preventDefault();
            const clipboardData = event.clipboardData || window.clipboardData;
            const pastedData = clipboardData.getData('text');
            if (!/^\d+$/.test(pastedData)) {
                return;
            }
            this.representanteLegal.numeroDocumento = pastedData;
        },
        /**
         * Abre el diálogo de dirección y realiza las siguientes acciones:
         * - Verifica si hay una dirección ya establecida en el campo this.registrarPrestador.direccion
         * - Verifica si la dirección proporcionada es urbana o rural.
         * - Si es urbana, procesa la información de la dirección urbana.
         * - Si es rural, procesa la información de la dirección rural.
         * - Actualiza las variables de estado para mostrar u ocultar elementos en el diálogo.
         */
        async abrirDialogoDireccion(){
            this.direccionSelecionada = this.datosAfiliado.direccion;
            this.municipioSeleccionado = this.nombreMunicipio;
            this.componenteAddress = true;
            this.onDialogAddressForm();
        },
        /**
         * Método que se llama cuando se muestra el diálogo del formulario de dirección.
         * Se asegura de que el formulario de dirección se llene con los datos correctos.
         */
        onDialogAddressForm() {
            this.$nextTick(() => {
                if (this.$refs.addressComponent) {
                    setTimeout(() => {
                        this.$refs.addressComponent.llenarFormularioDireccion();
                    }, 100)
                }
            });
        },
        /**
         * Actualiza los detalles de la dirección en el componente principal.
         * @param param0 - El objeto que contiene los detalles de la dirección.
         */
        actualizarDireccion({ direccionLista, barrioSelecionado, barrioOptional, tiposDireccion, tipoDireccion, datoAdicionalDireccion }) {
            this.datosAfiliado.direccion = '';
            this.datosAfiliado.direccion = direccionLista;
            this.barrioSelecionado = barrioSelecionado;
            this.barrioOpcional = barrioOptional;
            this.tiposDireccion = tiposDireccion;
            this.tipoDireccion = tipoDireccion;
            this.datoAdicionalDireccion = datoAdicionalDireccion;
            this.componenteAddress = false;
        },
        /**
         * Método para limpiar los campos del formulario de afiliado.
         * 
         * Este método establece todos los campos del objeto datosAfiliado a valores vacíos o nulos,
         * y restablece varias variables de estado relacionadas con la visualización de la información del formulario.
         */
        clear() {
            this.datosAfiliado.primerNombre = '';
            this.datosAfiliado.segundoNombre = '';
            this.datosAfiliado.primerApellido = '';
            this.datosAfiliado.segundoApellido = '';
            this.datosAfiliado.generoValue = '';
            this.datosAfiliado.genero = '';
            this.datosAfiliado.tipoDocumento = ''
            this.datosAfiliado.numeroDocumento = '';
            this.datosAfiliado.fechaNacimiento = '';
            this.datosAfiliado.departamento = '';
            this.datosAfiliado.email = '';
            this.datosAfiliado.actualizoUsuario = '';
            this.datosAfiliado.numeroCelular = '';
            this.datosAfiliado.numeroTelefono = '';
            this.datosAfiliado.codigoAseguradora = '';
            this.datosAfiliado.regimen = '';
            this.datosAfiliado.regimenValue = '';
            this.datosAfiliado.nivel = '';
            this.datosAfiliado.direccion = '';
            this.datosAfiliado.contrasena = '';
            this.datosAfiliado.confirmarContrasena = '';
            this.checkboxPrivacidad = false;
            this.barrioOpcional = '';
            this.tiposDireccion = '';
            this.tipoDireccion = '';
            this.show1 = false;
            this.show2 = false;
            this.$refs.observer?.reset();
        },
        /**
         * Método para redireccionar al usuario a la página de inicio de sesión externa.
         * Cambia la ubicación actual del navegador a la ruta de inicio de sesión externa.
         */
        redirectLogin() {
            window.location.href = `/#/external/login`;
            window.location.reload();
            this.clear();
        },
        /**
         * Recupera una lista de tipos de documentos desde el servidor y la almacena en la propiedad
         * 'tipDocuRL' del componente.
         * @throws {Error} Si ocurre un error durante la solicitud al servidor.
         */
        async listarTipDocuRL() {
            this.tipDocuRL = [];
            try {
                const response = await this.$http.get(`msa-external/public/tipo-documento/list`);
                const excludedTypes = ['TI', 'CN', 'CNV', 'MS', 'NV', 'RC'];
                const filteredData = response.data.filter(item => !excludedTypes.includes(item.tipoDocumento))
                this.tipDocuRL = filteredData.map(item => ({
                    text: `${item.descripcion}`,
                    value: item.tipoDocumento,
                }));
                this.representanteLegal.tipoDocumento = "CC";
            } catch (error) {
                this.manejarError(error);
            }
        },
        /**
         * Cierra y limpia el formulario para los datos del representante y resta.
         */
        cerrarDialogoRL() {
            this.dialogoRepresentanteLegal = false;
            this.representanteLegal.tipoDocumento = '';
            this.representanteLegal.numeroDocumento = null;
            this.representanteLegal.primerNombre = '';
            this.representanteLegal.segundoNombre = '';
            this.representanteLegal.primerApellido = '';
            this.representanteLegal.segundoApellido = '';
            this.representanteLegal.parentesco = null;
            this.representanteLegal.numeroCelular = null;
            this.representanteLegal.email = '';
            this.representanteLegal.nombreCompleto = '';
            this.$refs.observerRL?.reset();
        },
        /**
         * Abre el formulario de los datos del representante al asignar en true la variable dialogoRepresentanteLegal.
         * Llama al método listarTipDocuRL para colocar la cédula de ciudadanía por defecto.
         */
        abrirDialogoRL() {
            this.dialogoRepresentanteLegal = true;
            this.listarTipDocuRL();
        },
        /**
         * Muestra una notificación global en el componente.
         *
         * @param {string} mensaje - Mensaje que se mostrará en la notificación.
         * @param {string} tipo - Tipo de la notificación (por defecto, "error").
         * @param {string} icono - Icono de la notificación (por defecto, "highlight_off").
         */
        mostrarNotificacionGlobal(mensaje, tipo, icono) {
            this.mostrarNotificacion = true;
            this.tipoNotificacion = tipo || "advertencia"; // Tipo predeterminado es "error"
            this.tipoNotificacionColor = this.obtenerColorNotificacion(this.tipoNotificacion);
            this.iconoNotificacion = icono || "highlight_off"; // Icono predeterminado es "highlight_off"
            this.mensajeNotificacion = mensaje;
            this.iconoNotificacionClase = this.obtenerClaseIcono(this.tipoNotificacion);

            // Cierra la notificación después de 5 segundos
            setTimeout(() => {
                this.cerrarNotificacion();
            }, 5000); 
        },
        /**
         * Cierra la notificación
         */
        cerrarNotificacion() {
            this.mostrarNotificacion = false;
            this.mensajeNotificacion = "";
            this.tipoNotificacion = "";
        },
        /**
         * Obtiene el color correspondiente al tipo de notificación.
         * 
         * @param {string} tipo - Tipo de notificación.
         * @returns {string} - Color de la notificación.
         */
        obtenerColorNotificacion(tipo) {
            switch (tipo) {
                case "advertencia":
                return "#f80505";
                default:
                return "#f80505"; 
            }
        },
        /**
         * Obtiene la clase de icono correspondiente al tipo de notificación.
         * 
         * @param {*} tipo  - Tipo de notificación.
         * @returns {string} - Clase de icono.
         */
        obtenerClaseIcono(tipo) {
            switch (tipo) {
                case "advertencia":
                return "advertencia-icon";
                default:
                return "advertencia-icon";
            }
        },
        /**
         * Maneja errores y muestra notificaciones correspondientes.
         * 
         * @param {*} error - Objeto de error.
         */
        manejarError(error) {
            if (error.response) {
                if (error.response.status === 503) {
                    this.mostrarNotificacionGlobal("Error en la red, inténtalo más tarde.", "advertencia", "highlight_off" );
                } else if (error.response.status === 401) {
                this.mostrarNotificacionGlobal("Error de autorización, contacta con el administrador.", "advertencia", "highlight_off" );
                } else {
                    this.mostrarNotificacionGlobal("Error inesperado, contacta con el administrador.", "advertencia", "highlight_off");
                }
            } else {
                this.mostrarNotificacionGlobal("Error inesperado, contacta con el administrador.", "advertencia", "highlight_off");
            }
        },
    }
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.boton-actualizar {
    background-color: #0d47a1 !important;
    color: #fff;
    border-radius: 18px;
}
.shadow-img {
    filter: drop-shadow(0px 3px 3px rgba(63, 63, 65, 0.7));
}
.invisible-radio {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
}
.pieDePaginaContainer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #0d47a1; 
    height: 1.5rem; 
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.pieDePagina {
    color: white;
    font-size: small;
    margin-top: 0.3rem;
}
.notificacion {
  position: fixed;
  top: 50px;
  right: 20px;
  padding: 15px;
  border-radius: 5px;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
}
.advertencia {
  background-color: #ffffffd7 !important;
  color: #f80505;
}
.notificacion span:last-child {
  cursor: pointer;
  margin-right: 10px;
  padding-top: 3px;
}
.advertencia-icon {
  color: #f80505;
}
.rotate-animation-notification {
  animation: rotate-notification 1s ease-in-out; 
}
@keyframes rotate-notification  {
    0% {
        transform: rotateX(180deg);
    }

    50% {
        transform: rotateX(0deg);
    }

    100% {
        transform: rotateX(180deg);
    }
}
.size {
    font-size: xxx-large;
    font-weight: bold;
}
::v-deep .notificacionError .v-data-footer {
  padding-top: 10px;
  justify-content: end;
  border: none;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.46);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
}
.notificationValidation {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translateX(-50%);
  background-color: #5baa5e;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 15vw;
}
::v-deep .v-text-field__details {
    padding: 0 !important;
}
::v-deep .v-autocomplete.v-select.v-input--is-focused input {
    min-width: 0%;
}
</style>